import React from 'react'

const DarkAccr = () => {
  return (
    <div>
        <div className='lg:my-6 my-12  '>
            <h3 className='font-bold text-xl  text-center'>Accredition:</h3>
            <p className='lg:px-[400px] py-3 px-6  text-justify lg:text-center  '>We have obtained IP-1 (Infrastructure Provider Category-1) Certificate from the Department of Telecom(Govt. of India) to lease out or shell out the Infrastructure</p>
        </div>
    </div>
  )
}

export default DarkAccr