import React from 'react'
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import ShortTerm from "../components/Plans/ShortTerm"
const ShortTermPlans = () => {
  return (
    <div>
        <Navbar/>
        <ShortTerm/>
        <Footer/>
    </div>
  )
}

export default ShortTermPlans