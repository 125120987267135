import React from 'react'
import Navbar from "../components/Navbar"
import Banner from "../components/Plans/Banner"
import Footer from "../components/Footer"
import AllPlans from '../components/Plans/AllPlans'
import {Link} from "react-router-dom"
import AllCommercialPlans from "../components/Plans/AllCommercialPlans"
import { BadgeCheckIcon, ClockIcon,ArrowCircleRightIcon } from "@heroicons/react/outline";
const Plans = () => {
  return (
    <div>
        <Navbar/>
        <Banner/>
        <AllPlans/>
         <div className='my-6 py-6 text-center'>
         <h1 className='font-bold text-xl lg:text-3xl text-emerald-700'>We also have commercial Plans. Contact Us to Know more...</h1>
         <Link to="/contact" >
          <button className=" mt-5 lg:mt-5   whitespace-nowrap inline-flex items-center justify-center px-6 py-3 lg:px-8 lg:py-4 border border-transparent rounded-md shadow-sm text-base  lg:text-2xl text-white bg-emerald-600 hover:bg-emerald-700">
          
               Enquire<ArrowCircleRightIcon className=' h-8 w-8 ml-4 text-white text-lg'/>
               </button>
            </Link>
       </div>
        <Footer/>
    </div>
  )
}

export default Plans