import React from 'react'
import { yearlyData } from '../../constants/data'
import Card from '../Card'
const YearlyPlans = () => {
  return (
    <div className='flex justify-center items-center flex-wrap'>
         {yearlyData.map((plan,key)=>(
      <Card key={key} plan={plan}/>
    ))}
    </div>
  )
}

export default YearlyPlans