import React from 'react'
import Banner from "../components/Home/Banner"
import Navbar from "../components/Navbar"
import Options from "../components/Home/Options"
import AboutSection from "../components/Home/AboutSection"
import Footer from "../components/Footer"
import Clients from '../components/Home/Clients'
const Home = () => {
  return (
    <div>
      <Navbar/>
        <Banner/>
        <Options/>  
        <Clients/>
        <AboutSection/>   
        <Footer/>
    </div>
  )
}

export default Home