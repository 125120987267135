import React from 'react'

const DarkAbout = () => {
  return (
    <div>
        <div className=' my-6 lg:my-16'>
            <h1 className='text-center text-3xl lg:text-5xl font-bold'>About Us</h1>
            <p className='lg:text-center text-justify py-6 lg:px-[350px] px-[20px]'>We are Premium Telecom Service based in Mumbai. Intro Telecom network infrastructure is appropriate for catalyzing the growth of the internet and telcom networking in an infrastructure service market. Intro Telecom provides customer end-to-end connectivity Services.  </p>
        </div>
        <div className='flex py-12 items-center gap-6 flex-col lg:flex-row justify-center'>
            <div className='w-[300px] pb-6 lg:h-[200px] lg:px-6 lg:py-3 text-center shadow-md rounded-lg mx-16 bg-emerald-600'>
                <h1 className='font-bold  text-white text-3xl my-3'>Mission</h1>
                <p className='text-emerald-200 px-2'>Our Mision is to Be the best quality telecom infrastructure and service provider</p>
            </div>
             <div className='w-[300px] pb-6 lg:h-[200px] lg:px-6 lg:py-3 text-center shadow-md rounded-lg mx-16 bg-emerald-600'>
                <h1 className='font-bold text-white text-3xl my-3'>Vision</h1>
                <p className='text-emerald-200 px-2'>Our Vision is to make your life better.We Provide dark fiber in Mumbai City & Surrounding area</p>
            </div>
            <div className='w-[300px] pb-6 lg:h-[200px] lg:px-6 lg:py-3 text-center shadow-md rounded-lg mx-16 bg-emerald-600'>
                <h1 className='font-bold text-white text-3xl my-3'>Goals</h1>
                <p className='text-emerald-200 px-2'>To be leading Infrastructure Provider, to grow profitability by internal expansion and Acquisitions</p>
            </div>
        </div>
    </div>
  )
}

export default DarkAbout