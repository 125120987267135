import Amazon from "../assets/Amazon.png"
import epicon from "../assets/epicon.png"
import hotstar from "../assets/hotstar.png"
import playbox from "../assets/playbox.png"
import sun from "../assets/sun.png"
import Shemaroo from "../assets/Shemaroo.png"
import sony from "../assets/sony.png"
import Zee5 from "../assets/ZEE5.png"
import Voot from "../assets/Voot.png"

export const tenMbpsPlan=[
   {days:"30 Days",price:"330/-"},
   {days:"90 Days",price:"900/-"},
   {days:"180 Days",price:"1,700/-"},
   {days:"360 Days",price:"3,300/-"},
]

export const twentyMbpsPlan=[
 
  {days:"30 Days",price:"400/-"},
   {days:"90 Days",price:"1,100/-"},
   {days:"180 Days",price:"2,100/-"},
   {days:"360 Days",price:"4,000/-"},
]
export const twentyFiveMbpsPlan=[
  
  {days:"30 Days",price:"450/-"},
   {days:"90 Days",price:"1,200/-"},
   {days:"180 Days",price:"2,300/-"},
   {days:"360 Days",price:"4,500/-"},
]
export const fiftyMbpsPlan=[
   
  {days:"30 Days",price:"550/-"},
   {days:"90 Days",price:"1,500/-"},
   {days:"180 Days",price:"2,850/-"},
   {days:"360 Days",price:"5,500/-"},
]
export const hunderedMbpsPlan=[
  
  {days:"30 Days",price:"650/-"},
   {days:"90 Days",price:"1,800/-"},
   {days:"180 Days",price:"3,500/-"},
   {days:"360 Days",price:"6,500/-"},
]
export const oneFiftyMbpsPlan=[

  {days:"30 Days",price:"800/-"},
   {days:"90 Days",price:"2,250/-"},
   {days:"180 Days",price:"4,300/-"},
   {days:"360 Days",price:"8,000/-"},
]
export const twoHundredMbpsPlan=[
  {days:"30 Days",price:"950/-"},
   {days:"90 Days",price:"2,700/-"},
   {days:"180 Days",price:"5,000/-"},
   {days:"360 Days",price:"9,500/-"},
]
export const threeHundredMbpsPlan=[
  {days:"30 Days",price:"1,250/-"},
   {days:"90 Days",price:"3,600/-"},
   {days:"180 Days",price:"7,000/-"},
   {days:"360 Days",price:"12,500/-"},
]

export const monthlyData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"10Mbps",day:"30 Days",price:"330/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"20Mbps",day:"30 Days",price:"400/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"25Mbps",day:"30 Days",price:"4,500/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"50Mbps",day:"30 Days",price:"550/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"30 Days",price:"650/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"30 Days",price:"800/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"30 Days",price:"950/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"30 Days",price:"1,250/-"}
]

export const threeMonthData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"10Mbps",day:"90 Days",price:"900/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"20Mbps",day:"90 Days",price:"1,100/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"25Mbps",day:"90 Days",price:"1,200/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"50Mbps",day:"90 Days",price:"1,500/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"90 Days",price:"1,800/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"90 Days",price:"2,250/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"90 Days",price:"2,700/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"90 Days",price:"3,600/-"}
]

export const yearlyData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"10Mbps",day:"360 Days",price:"3,300/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"20Mbps",day:"360 Days",price:"4,000/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"25Mbps",day:"360 Days",price:"4,500/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"50Mbps",day:"360 Days",price:"5,500/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"360 Days",price:"6,500/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"360 Days",price:"8,000/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"360 Days",price:"9,500/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"360 Days",price:"12,500/-"}
]

export const sixMonthData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"10Mbps",day:"180 Days",price:"1,700/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"20Mbps",day:"180 Days",price:"2,100/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"25Mbps",day:"180 Days",price:"2,300/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"50Mbps",day:"180 Days",price:"2,850/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"180 Days",price:"3,500/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"180 Days",price:"4,300/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"180 Days",price:"5,000/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"180 Days",price:"7,000/-"}
]

export const ottData=[
    {title:"Plus",subTitle:"⭐⭐",plan:"80,000+ Movies, 300+ Live TV Channels ",day:"360 Days",price:"1,950/-",icons:[playbox,epicon,Shemaroo,Zee5,Voot]},
   {title:"Premium",subTitle:"⭐⭐⭐",plan:"1,20,000+ Movies, 350+ Live TV Channels ",day:"360 Days",price:"3,500/-",icons:[playbox,epicon,Shemaroo,Zee5,Voot,sony]},
   {title:"Platinum",subTitle:"⭐⭐⭐⭐",plan:"1,75,000+ Movies, 350+ Live TV Channels ",day:"360 Days",price:"4,400/-",icons:[playbox,epicon,Shemaroo,Zee5,Voot,sony,Amazon]},
  {title:"Titanium",subTitle:"⭐⭐⭐⭐⭐",plan:"2,00,000+ Movies, 350+ Live TV Channels ",day:"360 Days",price:"5,000/-",icons:[playbox,epicon,Shemaroo,Zee5,Voot,sony,Amazon,hotstar,sun]},
]