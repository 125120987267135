import React from 'react'
import Navbar from "../components/Navbar"
import LongTerm from "../components/Plans/LongTerm"
import Footer from "../components/Footer"
const LongTermPlans = () => {
  return (
    <div>
        <Navbar/>
        <LongTerm/>
        <Footer/>
    </div>
  )
}

export default LongTermPlans