import React from 'react'
import { sixMonthData } from '../../constants/data'
import Card from '../Card'
const SixMonth = () => {
  return (
     <div className='flex justify-center items-center flex-wrap'>
         {sixMonthData.map((plan,key)=>(
      <Card key={key} plan={plan}/>
    ))}
    </div>
  )
}

export default SixMonth