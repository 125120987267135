import React from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ContactForm from "../components/Contact/ContactForm"
import ContactContent from "../components/Contact/ContactContent"
const Contact = () => {
  return (
    <div>
        <Navbar/>
          <div className="flex justify-center  items-center p-3 lg:p-12 bg-gradient-to-r from-emerald-400 to-teal-300">
        <div className=" bg-white p-0 lg:p-6 rounded-lg  shadow-xl">
          <h1 className="text-3xl text-center lg:text-left mt-8 font-bold">
            Contact us today.
          </h1>
          <h1 className="text-3xl text-center lg:text-left font-bold">
            and enjoy.
          </h1>
          <div className="flex align-center lg:flex-row flex-col justify-between">
            {" "}
            <ContactForm />
            <ContactContent />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Contact