import React from 'react'
import Navbar from "../components/Navbar"
import DarkAbout from '../components/Plans/DarkAbout'
import DarkAccr from '../components/Plans/DarkAccr'
import DarkBanner from '../components/Plans/DarkBanner'
import DarkService from '../components/Plans/DarkService'
import Footer from "../components/Footer"
const DarkFibre = () => {
  return (
    <div>
        <Navbar/>
        <DarkBanner/>
        <DarkAbout/>
        <DarkService/>
        <DarkAccr/>
        <Footer/>
    </div>
  )
}

export default DarkFibre