import React from "react";
import { Fragment, useState } from "react";

import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import Swal from "sweetalert2";
import emailjs, { sendForm } from "emailjs-com";
import { send } from "emailjs-com";

const SERVICE_ID = "service_j9h8nva";
const TEMPLATE_ID = "template_e9u12y3";
const USER_ID = "user_J69VrtCkdHLM5v46iYtcN";

const plans = [
  {
    id: 0,
    name: "Choose Plans",
  },
  {
    id: 1,
    name: "1 Month Plan",
  },
  {
    id: 2,
    name: "1 Year Plan",
  },
  {
    id: 3,
    name: "3 Month Plan",
  },
  {
    id: 4,
    name: "6 Month Plan",
  },
];

const businessType = [
  {
    id: 0,
    name: "Choose a Business Type",
  },
  {
    id: 1,
    name: "Small Business",
  },
  {
    id: 2,
    name: "Personal",
  },
  {
    id: 3,
    name: "Large Family",
  },
  {
    id: 4,
    name: "Corporate",
  },
  {
    id: 5,
    name: "Large Business",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ContactForm = () => {
  const [toSend, setToSend] = useState({
    from_name: "",
    company_name: "",
    from_email: "",
    message: "",
    plan: "",
    company_type: "",
    contact: "",
  });
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
        setToSend({
          from_name: "",
          company_name: "",
          from_email: "",
          message: "",
          plan: "",
          company_type: "",
          contact: "",
        });
      })
      .catch((error) => {
        console.log("FAILED...", error);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      });
  };
  return (
    <div className="lg:pt-10 mr-0 lg:pb-12 lg:mr-12">
      <form className=" flex items-center lg:flex-row flex-col justify-around mb-4 mt-4">
        <input
          type="text"
          className="mt-1 lg:mr-4 mt-4 px-5 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
          placeholder="Enter your  name"
          name="from_name"
          value={toSend.from_name}
          onChange={handleChange}
        />

        <input
          type="text"
          className="mt-1 px-5 mt-4  lg:mr-4 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
          placeholder="Company name if any"
          name="company_name"
          value={toSend.company_name}
          onChange={handleChange}
        />
      </form>
      <div className=" flex lg:flex-row flex-col items-center justify-around mb-4 mt-4">
        <input
          type="email"
          name="from_email"
          value={toSend.from_email}
          onChange={handleChange}
          className="mt-1 lg:mr-4 mt-4 px-5 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
          placeholder="Enter your email"
        />
        <input
          type="tel"
          name="contact"
          value={toSend.contact}
          onChange={handleChange}
          className="mt-1 lg:mr-4 mt-4 px-5 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
          placeholder="Enter your phone number"
        />
      </div>
      <div className=" flex lg:flex-row flex-col items-center justify-around mb-4 mt-4">
        <select
          aria-label="Default select example"
          name="plan"
          value={toSend.plan}
          onChange={handleChange}
          className="mt-1 lg:mr-4 mt-4 px-5 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
        >
          {plans.map((plan) => (
            <option key={plan.id}>{plan.name}</option>
          ))}
        </select>
        <select
          aria-label="Default select example"
          name="company_type"
          value={toSend.company_type}
          onChange={handleChange}
          className="mt-1 lg:mr-4 mt-4 px-5 py-5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-64 rounded-md sm:text-sm focus:ring-1"
        >
          {businessType.map((plan) => (
            <option key={plan.id}>{plan.name}</option>
          ))}
        </select>
      </div>
      <div className="flex align-center justify-center lg:justify-start  ">
        <textarea
          id="message"
          name="message"
          rows={3}
          value={toSend.message}
          onChange={handleChange}
          className="shadow-sm focus:ring-indigo-500 p-4 focus:border-indigo-500 mt-1 block  w-64 lg:w-10/12 h-36 sm:text-sm border border-gray-300 rounded-md"
          placeholder="Enter your message"
        />
      </div>
      <div className="mt-6 flex lg:align-center justify-center">
        <button
          type="submit"
          onClick={handleOnSubmit}
          className="p-4 bg-gradient-to-r from-emerald-600 to-teal-600 rounded-lg text-white text-lg w-36"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
