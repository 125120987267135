import React from "react";
import {
  BrowserRouter,
Route,
  Routes
} from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About"
import Contact from "./Pages/Contact";
import Plans from "./Pages/Plans";
import ShortTermPlans from "./Pages/ShortTermPlans";
import LongTermPlans from "./Pages/LongTermPlans";
import OTT from "./Pages/OTT";
import DarkFibre from "./Pages/DarkFibre";
function App() {
  return (
    <div>
      <Routes>
      <Route exact path="/" element={<Home/>}   />
       <Route exact path="/about" element={<About/>}   />
       <Route exact path="/contact" element={<Contact/>}   />
       <Route exact path="/plans" element={<Plans/>}   />
        <Route exact path="/shortterm" element={<ShortTermPlans/>}   />
        <Route exact path="/longterm" element={<LongTermPlans/>}   />
        <Route exact path="/ott" element={<OTT/>}   />
         <Route exact path="/darkFibre" element={<DarkFibre/>}   />
    </Routes>
    </div>
  );
}

export default App;
