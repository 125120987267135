import React from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Banner from "../components/about/Banner"
import OurVision from "../components/about/OurVision"
import WhatWeDo from "../components/about/WhatWeDo"
const About = () => {
  return (
    <div>
        <Navbar/>
        <Banner/>
        <OurVision/>
        <WhatWeDo/>
        <Footer/>
    </div>
  )
}

export default About