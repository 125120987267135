import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import baroda from "../../assets/clients/baroda.jpg";
import barbeque from "../../assets/clients/barbeque.jpg";
import citylight from "../../assets/clients/citylight.jpg";
import mahanagar from "../../assets/clients/mahanagar.jpg";
import baal from "../../assets/clients/baal.jpg";
import mumbai from "../../assets/clients/mumbai.jpg";
const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <div className="lg:my-12 my-6">
        <h1 className="text-center text-3xl lg:text-5xl font-bold">Clients</h1>
      </div>
      <div className="lg:px-16 px-12">
        <Carousel
          swipeable={false}
          draggable={false}
          autoPlay={true}
          infinite={true}
          responsive={responsive}
        >
          <div className="px-6">
            <img className="w-64 " src={baroda} alt="" />
          </div>
          <div className="px-6">
            <img className="w-64" src={barbeque} alt="" />
          </div>
          <div className="px-6">
            <img className="w-64" src={mahanagar} alt="" />
          </div>
          <div className="px-6">
            <img className="w-64" src={citylight} alt="" />
          </div>
          <div className="px-6">
            <img className="w-64" src={mumbai} alt="" />
          </div>
          <div className="px-6">
            <img className="w-64" src={baal} alt="" />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Clients;
