import React from 'react'
import darkService from "../../assets/darkService.svg"
const DarkService = () => {
  return (
    <div>
        <div className='my-6'>
            <h1 className='text-center font-bold text-4xl'>O & M Services</h1>
        </div>
        <div className='flex items-center justify-center flex-col md:flex-row'>
            <div className='mx-6'>
                <img width="400" height="400" src={darkService} alt="" />
            </div>
            <div className='lg:w-[700px] lg:text-justify mx-6 text-gray-700'>
                <p className='text-[20px] ' > Our <strong> Operations & Maintenance</strong> team monitors the OFC route to avoid <strong>physical damage</strong> to ensure <strong>high uptimes</strong> of the link signal.</p>
                <hr className='my-6' />
                <p className='text-[20px]'> We have our own eqipment like <strong>splicing machine,</strong> <strong>OTDR</strong> and <strong>optic power</strong> meter to rectify the out coming problem in the network and maintain the <strong>minimum down-time </strong> of the network.</p>
            </div>
        </div>
    </div>
  )
}

export default DarkService